import * as React from "react";
import Layout from '../../components/Layout'
import {MainText} from "../../components/MainComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    Area,
    AreaDescription,
    AreaIcon,
    BannerContainer,
    BoldText,
    BoxAreas,
    Btn,
    CampSectionHeading,
    CustomerPoint,
    DescriptionText,
    FeatureBox,
    FeatureBoxHeader,
    FeatureBoxText,
    FeatureContainerSken,
    FeatureContainerTop,
    FeatureGrid,
    FeatureImageContainer,
    FeatureText,
    FeatureTextContainer,
    FeatureTextContainerUnit,
    Motivation,
    PlanPrice,
    PriceColumn,
    PriceColumnFooter,
    PriceColumnHeader,
    ProgramName,
    PromoContainer,
    ReferenceGrid,
    ScanRefImage,
    SubText,
    TableContainer,
    TextWrapper,
    TopGrid,
    Wrapper,
} from "../../components/Skener/layout";
import {MainSection, TopPhotoFrame} from "../../components/common";
import Helmet from "react-helmet";
import Collapsible from "react-collapsible";
import {
    faBug,
    faCheckSquare,
    faDatabase,
    faEllipsisH,
    faFileInvoice,
    faListUl,
    faPoll,
    faServer,
    faShieldAlt,
    faShieldVirus
} from "@fortawesome/free-solid-svg-icons";

export default () => {
    const emailElement = <a href={"mailto:info@tunasec.com"}>info@tunasec.com</a>
    return (
        <Layout>
            <Helmet titleTemplate={`WebScan - dostupný test pro váš web a doménu | TunaSec`}>
                {/* General tags */}
                <title>WebScan - dostupný test pro váš web a doménu | TunaSec</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="penetrační testování, skener zranitelností, webový skener, it security, it bezpečnost" />
                <meta property="og:title" content="WebScan - dostupný test pro váš web a doménu | TunaSec"/>
                <meta name="description" content="WebScan je nástroj na skenování slabých míst v bezpečnosti webových stránek a serverů. Díky němu se můžete vyhnout drahým únikům dat." />
                <meta name="og:description" content="WebScan je nástroj na skenování slabých míst v bezpečnosti webových stránek a serverů. Díky němu se můžete vyhnout drahým únikům dat." />
                <meta name="og:image" content="https://tunasec.com/img/og-images/webscan.png" />
            </Helmet>
            <TopPhotoFrame className="full-width-image margin-top-0">
                <TopGrid>
                    <MainText>WebScan</MainText>
                    <SubText>Dostupný test zranitelností vašeho webu a serveru</SubText>
                </TopGrid>
            </TopPhotoFrame>
            <MainSection className="section section--gradient container">
                <PromoContainer>
                    <CampSectionHeading>Proč skenovat svůj web</CampSectionHeading>
                    <FeatureContainerSken>
                        <FeatureTextContainer>
                            <Area>
                                <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faShieldAlt}/></AreaIcon>
                                <BoldText>Prevence před bezpečnostními incidenty</BoldText>
                                <AreaDescription>
                                    Náš cíl zvýšení povědomí v oblasti IT bezpečnosti naplňujeme hlavně <strong>vzděláváním v školách a jiných vzdělávacích institucích</strong>. Pravidelně se účastníme jako přednášející na různých konferencích v ČR i v zahraničí.
                                </AreaDescription>
                            </Area>
                        </FeatureTextContainer>
                        <FeatureTextContainer>
                            <Area>
                                <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faFileInvoice}/></AreaIcon>
                                <BoldText>Report o bezpečnosti vašeho webu</BoldText>
                                <AreaDescription>
                                    Skener pustí <strong>více než 5000</strong> testů na váš web a v reportu najdete objevené chyby.
                                    Máme také inteligentní reportování seřazené podle závažnosti, co je nutné opravit nejdříve a co nehoří.
                                </AreaDescription>
                            </Area>
                        </FeatureTextContainer>
                        <FeatureTextContainer>
                            <Area>
                                <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faBug}/></AreaIcon>
                                <BoldText>Najdeme bezpečnostní chyby před hackery</BoldText>
                                <AreaDescription>
                                    Když dokážete rychle detekovat chyby v bezpečnosti vašeho webu, tak minimalizujete okno možného napadení útočníkem.
                                    <strong>Ušetříte tím finance za mitigaci útoku a neutrpí reputace vás a vaší organizace.</strong>
                                </AreaDescription>
                            </Area>
                        </FeatureTextContainer>
                        <FeatureTextContainer>
                            <Area>
                                <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faPoll}/></AreaIcon>
                                <BoldText>Sledování vývoje bezpečnosti webu</BoldText>
                                <AreaDescription>
                                    Je možnost dělat automatický sken pravidelně, například jednou za měsíc.
                                    Výhodou tohoto postupu je, že budete <strong>mít představu o stavu bezpečnosti vaší webové stránky</strong>.
                                </AreaDescription>
                            </Area>
                        </FeatureTextContainer>
                    </FeatureContainerSken>
                    <CampSectionHeading>Automatizovaný skener TunaSec WebScan</CampSectionHeading>
                    <FeatureContainerTop>
                        <FeatureTextContainer>
                            <FeatureTextContainerUnit>
                                <BoldText>Pro koho</BoldText>
                                <ul>
                                    <CustomerPoint>Malé a středně velké organizace</CustomerPoint>
                                    <CustomerPoint>Osobní stránky a blogy</CustomerPoint>
                                    <CustomerPoint>Neziskové organizace</CustomerPoint>
                                </ul>
                            </FeatureTextContainerUnit>
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <img src={"/img/pages/awscan.png"} alt="Skener operator"/>
                        </FeatureImageContainer>
                    </FeatureContainerTop>
                    <FeatureTextContainer>
                        <FeatureTextContainerUnit>
                            <BoldText style={{marginLeft:"10px"}}>Co všechno testujeme</BoldText>
                            <DescriptionText>
                                Zaměřujeme se na testování známých bezpečnostních zranitelností na úrovni webů a webových serverů.
                                <br/><br/>
                                Konkrétně jde o následující oblasti:
                            </DescriptionText>
                            <BoxAreas>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faShieldVirus}/></AreaIcon>
                                    Zranitelnosti, které máte na webu
                                    <AreaDescription>
                                        Skenujeme velkou část webových zranitelností, které vyšly v posledních 21 letech. Pokud máte nějakou dobu neaktualizovaný web tak je možné, že objevíme některou z těchto zranitelností.
                                    </AreaDescription>
                                </Area>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faDatabase}/></AreaIcon>
                                    Otevřené služby a porty na serverech
                                    <AreaDescription>
                                        Máte dostupnou databázi, testovací prostředí nebo nástroj na sdílení souborů o kterém nevíte že je otevřen do internetu? My to zjistíme a doporučíme kroky, jak zabezpečit svůj server.
                                    </AreaDescription>
                                </Area>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faCheckSquare}/></AreaIcon>
                                    OWASP TOP 10 checklist
                                    <AreaDescription>
                                        Nejčastější webové zranitelnosti jsou také podchyceny našim skenerem. Na jejich seznam se můžete podívat <a href={"https://owasp.org/Top10/"}>na stránce oficiálního OWASP TOP 10 projektu</a>.
                                    </AreaDescription>
                                </Area>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faServer}/></AreaIcon>
                                    Webový server a third party software
                                    <AreaDescription>
                                        Zjistíme, na čem běží vaše webová stránka a jaký software třetích stran používáte. V případě starších a neudržovaných verzí doporučíme jejich aktualizaci.
                                    </AreaDescription>
                                </Area>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faListUl}/></AreaIcon>
                                    Subdomény, které máte k dispozici
                                    <AreaDescription>
                                        Upozorníme vás na zapomenuté, případně špatně nakonfigurované subdomény ve vašich DNS nastaveních. Také tím minimalizujete možnost útoku DNS takeover.
                                    </AreaDescription>
                                </Area>
                                <Area>
                                    <AreaIcon><FontAwesomeIcon color={"#3f4c77"} icon={faEllipsisH}/></AreaIcon>
                                    A mnoho dalšího
                                    <AreaDescription>
                                        Přijdeme na implicitní jména a hesla do vašich systémů a doporučíme jejich změnu. Také hledáme zapomenuté veřejně dostupné soubory jako zálohy, otevřené API, konfigurační soubory, logy a různé tokeny. Skenujeme služby které používáte a hledáme jejich špatné nastavení / miskonfiguraci.
                                    </AreaDescription>
                                </Area>
                            </BoxAreas>
                        </FeatureTextContainerUnit>
                    </FeatureTextContainer>
                    {/*<CampSectionHeading>Ceník</CampSectionHeading>*/}
                    {/*<TableContainer>*/}
                    {/*    <PriceColumn isMobileVisible={true} style={{opacity: 1}}>*/}
                    {/*        <PriceColumnHeader>*/}
                    {/*            <Motivation>ESSENTIAL</Motivation>*/}
                    {/*            <ProgramName>Základní sken</ProgramName>*/}
                    {/*            <FeatureGrid>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Automatizovaný sken</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>WebScan kompletně oskenuje vaší stránku a server</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Výstup ve formě PDF</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Report jsme připravili s maximálním důrazem na čitelnost i pro netechnické lidi</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Konzultace reportu</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Skonzultujeme s vámi výstupy ze skenu</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*            </FeatureGrid>*/}
                    {/*        </PriceColumnHeader>*/}
                    {/*        <PriceColumnFooter>*/}
                    {/*            <PlanPrice>2 490 CZK</PlanPrice>*/}
                    {/*        </PriceColumnFooter>*/}
                    {/*    </PriceColumn>*/}

                    {/*    <PriceColumn isMobileVisible={true} style={{opacity: 1}}>*/}
                    {/*        <PriceColumnHeader>*/}
                    {/*            <Motivation>ADVANCED</Motivation>*/}
                    {/*            <ProgramName>Pokročilý sken</ProgramName>*/}
                    {/*            <FeatureGrid>*/}
                    {/*                <FeatureText>Všechno z ESSENTIAL skenu</FeatureText>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Manuální verifikace zranitelností</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Na web a server se podíváme i manuálně a zistíme, kde můžou být další problémy které automatizovaný sken prohlídnul.</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Pomoc s opravou nalezených zranitelností a chyb</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Doporučíme postup oprav a jejich možné způsoby pro jednotlivé situace</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*            </FeatureGrid>*/}
                    {/*        </PriceColumnHeader>*/}
                    {/*        <PriceColumnFooter>*/}
                    {/*            <PlanPrice>7 990 CZK</PlanPrice>*/}
                    {/*        </PriceColumnFooter>*/}
                    {/*    </PriceColumn>*/}

                    {/*    <PriceColumn isMobileVisible={true} style={{opacity: 1}}>*/}
                    {/*        <PriceColumnHeader>*/}
                    {/*            <Motivation>PERSISTENT</Motivation>*/}
                    {/*            <ProgramName>Dlouhodobý sken</ProgramName>*/}
                    {/*            <FeatureGrid>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Automatizovaný sken jednou za měsíc</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Váš web oskenujeme jednou do měsíce a pošleme report se zranitelnostma</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Dlouhodobá ochranu webu</FeatureBoxHeader>*/}
                    {/*                    <FeatureBoxText>Každý měsíc máte report o nových zranitelnostech na webu</FeatureBoxText>*/}
                    {/*                </FeatureBox>*/}
                    {/*                <FeatureBox>*/}
                    {/*                    <FeatureBoxHeader>Předplatné trvá 12 měsíců</FeatureBoxHeader>*/}
                    {/*                </FeatureBox>*/}
                    {/*            </FeatureGrid>*/}
                    {/*        </PriceColumnHeader>*/}
                    {/*        <PriceColumnFooter>*/}
                    {/*            <PlanPrice>19 990 CZK</PlanPrice>*/}
                    {/*        </PriceColumnFooter>*/}
                    {/*    </PriceColumn>*/}
                    {/*</TableContainer>*/}

                    <BannerContainer>
                        <TextWrapper>
                            <h3>
                                Poptat službu přes formulář
                            </h3>
                            <p>
                                Vyplňte základní údaje o vás a my se vám ozveme.
                            </p>
                        </TextWrapper>
                        <Btn to={"https://forms.gle/cigiogPq67UJkqoY8"} target={"_blank"}>
                            Vyplnit formulář
                        </Btn>
                    </BannerContainer>

                    <CampSectionHeading>S bezpečností jsme pomohli</CampSectionHeading>
                    <ReferenceGrid>
                        <ScanRefImage src={"/img/pages/cooperation-liferay.png"} alt={"Liferay logo"}/>
                        <ScanRefImage src={"/img/pages/cooperation-deepnote.png"} alt={"Deepnote logo"}/>
                        <ScanRefImage src={"/img/pages/cooperation-pap.png"} alt={"PAP logo"}/>
                    </ReferenceGrid>

                    <CampSectionHeading>Časté dotazy</CampSectionHeading>
                    <Wrapper>
                        <Collapsible trigger="Co dostanu za výše uvedenou sumu?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Ve zkratce - získate přehled o kyberbezpečnosti vašeho webu a serveru.
                                Tento přehled vám bude poslán na email ve formě PDF reportu, abyste mohli opravit nalezené chyby. Report nalezených zranitelností je v anglickém jazyce.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Je možné objednat službu pravidelně několikrát do roka?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Určitě, je to ideální přístup pro firmy, které chtějí kontinálně testovat jejich bezpečnost.
                                V rámci ceníku máme výhodnější cenu za službu PERSISTENT, jde právě o dlouhodobou (roční) službu automatizovaného skenu.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Dokážete odchytit všechny chyby na webu?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Ne, to ani není smyslem tohto typu testu. Náš způsob testování detekuje hlavně zjevné chyby, které máte na webu. Pro komplexní testování webů jsou k dispozici penetrační testy se zapojením vícero lidí, které se pohybují v desítkách až stovkách tisíc korun.
                                Ozvěte se nám na mail {emailElement} a domluvíme se na produktu na míru.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Poskytujete lepší ceny pro neziskové organizace?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Ano! TunaSec je také neziskovka a jeden z hlavních pilířů je bezpečnější web.
                                Napište nám na {emailElement} a společně se dohodneme na individuálním nacenění. S neziskovými organizacemi chceme mít dlouhodobou spolupráci.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Jaké často je skener aktualizován?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Na skeneru pracujeme pravidelně a počet testů, které spouští na webu se kontinuálně zvyšuje.
                                Je to kvůli tomu, že počet webových zranitelností se zvyšuje každým dnem.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Co dostanu jako výstup ze skeneru?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Výstupem je dokument ve formátu PDF, který obsahuje seznam zranitelností nalezených na vašem webu a serveru.
                                Report je logicky strukturován a zranitelnosti seřazeny podle závažnosti a dopadu jejich využití / exploitace.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Jaké máte zkušenosti s kyberbezpečností?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Jsme tým pěti lidí a každý z nás má zkušenost s kyberbezpečností v rozsahu 5 až 15 let.
                                V týmu má většina z nás certifikace praktického penetračného testování (od firem Offensive Security a Portswigger).
                                Organizujeme CTF eventy na koferencích OWASP a máme za sebou vícero penetračních testů pro naše klienty. Více o nás na stránce <a href={"/o-nas/"}>O nás</a>.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Jaká můžeme očekávat zatížení serverů?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Během hodiny přijde na váš server přibližně 50000 dotazů.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Mám zájem o službu, která zde není uvedená."
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Napište nám na email {emailElement} s tím, o jakou službu máte zájem a dohodneme spolupráci.
                                Mezi časté služby patří penetrační testy, audit, konzultace v oblasti kyberbezpečnosti, školení nebo phishingová kampaň.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="V rámci naší domény máme vícero subdomén. Kolik jich oskenujete v rámci vašeho skenu?"
                                     className="faq"
                                     openedClassName="faq active"
                                     triggerClassName="faq-title"
                                     triggerOpenedClassName="faq-title active"
                                     triggerTagName="button"
                                     contentInnerClassName="faq-content"
                                     transitionTime={300}
                                     easing="ease-out"
                        >
                            <p>
                                Skener je aktuálně nastaven na kompletní oskenování 3 subdomén, které poskytnete z vaší strany. V případě širšího záběru skenu se domluvíme individuálně.
                                Seznam subdomén, které máte vystavené do internetu bude ale obsahovat všechny ty, které dokážeme najít.
                            </p>
                        </Collapsible>
                    </Wrapper>
                </PromoContainer>
            </MainSection>
        </Layout>
    );
}
